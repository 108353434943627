import React from 'react';
import { Link, navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import * as styles from './index.module.scss';
import axios from 'axios';
import GameContainer from '../GameContainer';
import PageLayout from '../PageLayout';
import config from '../../config';
import Button from '../Button';

// markup
const Create = () => {
    const [name, setName] = React.useState('');
    const [wordList, setWordList] = React.useState('english');
    const [isPrivate, setIsPrivate] = React.useState(true);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [maxUsers, setMaxUsers] = React.useState(10);
    const [minUsers, setMinUsers] = React.useState(2);
    const [rounds, setRounds] = React.useState(10);
    const [numPickableWords, setNumPickableWords] = React.useState(2);
    const [drawTime, setDrawTime] = React.useState(70);
    const [maxGuesses, setMaxGuesses] = React.useState(-1);
    const [error, setError] = React.useState(null);
    const [customWords, setCustomWords] = React.useState('');
    const [blendCustomWords, setBlendCustomWords] = React.useState(false);
    const [useCustomWords, setUseCustomWords] = React.useState(false);
    const [gameMode, setGameMode] = React.useState<number>(1);
    const refName = React.useRef(null);

    React.useEffect(() => {
        if (refName.current) {
            refName.current.focus();
        }
    }, []);

    const getLanguage = () => {
        switch (wordList) {
            case 'general_en':
            case 'christmas_en':
            case 'sentences_en':
                return 'en';
            case 'general_is':
            case 'christmas_is':
                return 'is';
            default:
                return 'en';
        }
    };

    const customWordsFormatted = customWords
        .split(',')
        .map((w) => w.trim().toLowerCase())
        .filter((w) => w.length > 0);

    const onSubmit = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        const submitRoom = async () => {
            try {
                const isLocal =
                    typeof window !== 'undefined' &&
                    window.location.hostname === 'localhost';
                const res = await axios.post(
                    `${
                        isLocal ? config.gameApiLocal : config.gameApiProd
                    }/room`,
                    {
                        name,
                        wordList,
                        isPrivate,
                        maxUsers,
                        minUsers,
                        drawTime,
                        rounds,
                        maxGuesses,
                        numPickableWords,
                        customWords: useCustomWords
                            ? customWordsFormatted
                            : null,
                        blendCustomWords: useCustomWords
                            ? blendCustomWords
                            : false,
                        language: getLanguage(),
                        gameMode,
                    }
                );

                if (res.data && res.data.id) {
                    navigate(`/room/${res.data.id}`);
                }
            } catch (err) {
                console.log(err);
                setIsSubmitting(false);
                setError('error');
            }
        };

        setIsSubmitting(true);
        setError(null);
        submitRoom();
    };

    const isFormValid =
        maxUsers > 0 &&
        name.length > 0 &&
        (useCustomWords === false ||
            blendCustomWords ||
            (useCustomWords === true && customWordsFormatted.length >= 10));

    const onMaxUsersChange = (e) => {
        if (e.target.value == null || e.target.value == '') {
            setMaxUsers(1);
        }
        const value = parseInt(e.target.value);
        if (value > 0 && value <= 100) {
            setMaxUsers(value);
        }
    };

    const onMinUsersChange = (e) => {
        if (e.target.value == null || e.target.value == '') {
            setMinUsers(2);
        }
        const value = parseInt(e.target.value);
        if (value > 1 && value <= 100) {
            setMinUsers(value);
        }
    };

    React.useEffect(() => {
        switch (gameMode) {
            case 1:
                setMaxGuesses(-1);
                setMinUsers(2);
                break;
            case 2:
                setRounds(5);
                setMinUsers(3);
                break;
        }
    }, [gameMode]);

    return (
        <PageLayout>
            <Helmet>
                <title>Create custom game - Skissan</title>
            </Helmet>
            <h1>
                <Link to='/games'>
                    <img src='/skissan.svg' />
                </Link>
            </h1>

            <h2>Create custom game</h2>

            <GameContainer className={styles.create}>
                <form onSubmit={onSubmit}>
                    <div>
                        <label className={styles.label} htmlFor='name'>
                            Name
                        </label>
                        <input
                            autoComplete='off'
                            id='name'
                            name='name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            type='text'
                            disabled={isSubmitting}
                            ref={refName}
                            maxLength={30}
                            placeholder='Enter a room name'
                            className='input'
                        />
                    </div>
                    <div>
                        <label className={styles.label} htmlFor='gameMode'>
                            Game
                        </label>
                        <select
                            id='gameMode'
                            value={gameMode}
                            onChange={(e) =>
                                setGameMode(parseInt(e.target.value))
                            }
                            disabled={isSubmitting}
                            className='input'
                        >
                            <option value='1'>Draw & guess</option>
                            <option value='2'>Draw & guess from answers</option>
                            <option value='3' disabled>
                                Drawing for fun with others
                            </option>
                        </select>
                    </div>
                    <div>
                        <label className={styles.label} htmlFor='wordlist'>
                            Word list
                        </label>
                        <select
                            id='wordlist'
                            value={wordList}
                            onChange={(e) => setWordList(e.target.value)}
                            disabled={isSubmitting}
                            className='input'
                        >
                            <optgroup label='English'>
                                <option value='general_en'>English</option>
                                <option value='sentences_en'>Sentences</option>
                                <option value='christmas_en'>Christmas</option>
                            </optgroup>
                            <optgroup label='Íslenska'>
                                <option value='general_is'>Íslenska</option>
                                <option value='christmas_is'>Jól</option>
                            </optgroup>
                        </select>
                    </div>
                    <div>
                        <label className={styles.label} htmlFor='rounds'>
                            Rounds
                        </label>
                        <select
                            id='rounds'
                            value={rounds}
                            onChange={(e) =>
                                setRounds(parseInt(e.target.value))
                            }
                            disabled={isSubmitting}
                            className='input'
                        >
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                            <option value='6'>6</option>
                            <option value='7'>7</option>
                            <option value='8'>8</option>
                            <option value='9'>9</option>
                            <option value='10'>10</option>
                            <option value='15'>15</option>
                            <option value='20'>20</option>
                            <option value='25'>25</option>
                            <option value='30'>30</option>
                            <option value='40'>40</option>
                            <option value='50'>50</option>
                        </select>
                    </div>
                    <div>
                        <label className={styles.label} htmlFor='drawtime'>
                            Drawing time in seconds
                        </label>
                        <select
                            id='drawtime'
                            value={drawTime}
                            onChange={(e) =>
                                setDrawTime(parseInt(e.target.value))
                            }
                            disabled={isSubmitting}
                            className='input'
                        >
                            <option value='5'>5</option>
                            <option value='10'>10</option>
                            <option value='15'>15</option>
                            <option value='20'>20</option>
                            <option value='25'>25</option>
                            <option value='30'>30</option>
                            <option value='35'>35</option>
                            <option value='40'>40</option>
                            <option value='45'>45</option>
                            <option value='50'>50</option>
                            <option value='55'>55</option>
                            <option value='60'>60</option>
                            <option value='65'>65</option>
                            <option value='70'>
                                70{gameMode === 1 && ' default'}
                            </option>
                            <option value='75'>75</option>
                            <option value='80'>80</option>
                            <option value='85'>85</option>
                            <option value='90'>90</option>
                            <option value='95'>95</option>
                            <option value='100'>100</option>
                            <option value='120'>120</option>
                            <option value='150'>150</option>
                            <option value='200'>200</option>
                            <option value='300'>300</option>
                            <option value='400'>400</option>
                            <option value='500'>500</option>
                            <option value='1000'>1000</option>
                        </select>
                    </div>
                    {gameMode === 1 && (
                        <div>
                            <label
                                className={styles.label}
                                htmlFor='numPickableWords'
                            >
                                How many words the drawer can pick from
                            </label>
                            <select
                                id='numPickableWords'
                                value={numPickableWords}
                                onChange={(e) =>
                                    setNumPickableWords(
                                        parseInt(e.target.value)
                                    )
                                }
                                disabled={isSubmitting}
                                className='input'
                            >
                                <option value='1'>1</option>
                                <option value='2'>2 (default)</option>
                                <option value='3'>3</option>
                                <option value='4'>4</option>
                                <option value='5'>5</option>
                                <option value='6'>6</option>
                            </select>
                        </div>
                    )}
                    {gameMode === 1 && (
                        <div>
                            <label
                                className={styles.label}
                                htmlFor='maxGuesses'
                            >
                                Maximum guesses per round
                            </label>
                            <select
                                id='maxGuesses'
                                value={maxGuesses}
                                onChange={(e) =>
                                    setMaxGuesses(parseInt(e.target.value))
                                }
                                disabled={isSubmitting}
                                className='input'
                            >
                                <option value='-1'>Infinite (default)</option>
                                <option value='1'>1</option>
                                <option value='2'>2</option>
                                <option value='3'>3</option>
                                <option value='4'>4</option>
                                <option value='5'>5</option>
                                <option value='6'>6</option>
                                <option value='7'>7</option>
                                <option value='8'>8</option>
                                <option value='9'>9</option>
                                <option value='10'>10</option>
                                <option value='15'>15</option>
                                <option value='20'>20</option>
                                <option value='25'>25</option>
                                <option value='30'>30</option>
                                <option value='40'>40</option>
                                <option value='50'>50</option>
                                <option value='100'>100</option>
                                <option value='200'>200</option>
                            </select>
                        </div>
                    )}
                    <div>
                        <label className={styles.label} htmlFor='maxUsers'>
                            Maximum number of players
                        </label>
                        <input
                            id='maxUsers'
                            min={1}
                            max={100}
                            maxLength={3}
                            value={maxUsers}
                            onChange={onMaxUsersChange}
                            disabled={isSubmitting}
                            type='number'
                            className='input'
                        />
                    </div>
                    <div>
                        <label className={styles.label} htmlFor='minUsers'>
                            Minimum number of players needed to start the game
                        </label>
                        <input
                            id='minUsers'
                            min={2}
                            max={100}
                            maxLength={3}
                            value={minUsers}
                            onChange={onMinUsersChange}
                            disabled={isSubmitting}
                            type='number'
                            className='input'
                        />
                    </div>
                    <div>
                        <label htmlFor='useCustomWords'>
                            <input
                                checked={useCustomWords}
                                onChange={(e) =>
                                    setUseCustomWords(e.target.checked)
                                }
                                id='useCustomWords'
                                name='useCustomWords'
                                type='checkbox'
                            />{' '}
                            Use custom words
                        </label>
                    </div>
                    {useCustomWords && (
                        <div className={styles.customWordsContainer}>
                            <label
                                className={styles.label}
                                htmlFor='customWords'
                            >
                                {customWordsFormatted.length} custom words{' '}
                                <span>
                                    {customWordsFormatted.length < 10 &&
                                        !blendCustomWords && (
                                            <>
                                                {10 -
                                                    customWordsFormatted.length}{' '}
                                                needed
                                            </>
                                        )}
                                </span>
                            </label>
                            <textarea
                                id='customWords'
                                className='input'
                                value={customWords}
                                onChange={(e) => setCustomWords(e.target.value)}
                                disabled={isSubmitting}
                                placeholder='Words must be separated by comma'
                            />
                            <div className={styles.customWordsHelper}>
                                <p>
                                    Don't worry about trailing spaces or extra
                                    commas, we take care of it. Custom words
                                    must be comma separated, example:
                                </p>
                                <input
                                    type='text'
                                    readOnly
                                    disabled
                                    value='airplane, flying trampoline, boat'
                                    className='input'
                                />
                            </div>

                            <label htmlFor='blendCustomWords'>
                                <input
                                    checked={blendCustomWords}
                                    onChange={(e) =>
                                        setBlendCustomWords(e.target.checked)
                                    }
                                    id='blendCustomWords'
                                    name='blendCustomWords'
                                    type='checkbox'
                                />
                                {` `}
                                Mix custom words
                                <span>with the selected word list</span>
                            </label>
                        </div>
                    )}

                    {error != null && (
                        <p>There was a problem creating your room. Sorry!</p>
                    )}

                    <div>
                        <label htmlFor='isPrivate'>
                            <input
                                checked={isPrivate}
                                onChange={(e) => setIsPrivate(e.target.checked)}
                                id='isPrivate'
                                name='isPrivate'
                                type='checkbox'
                            />{' '}
                            Private room{' '}
                            <span>Not visible in the games list</span>
                        </label>
                    </div>
                    <Button
                        color='primary'
                        size='large'
                        type='submit'
                        disabled={isSubmitting || !isFormValid}
                    >
                        Create room
                    </Button>
                </form>
            </GameContainer>
            <div style={{ marginTop: '2rem' }}>
                <Link to='/games'>← Back to games list</Link>
            </div>
        </PageLayout>
    );
};

export default Create;
