import React from 'react'
import Create from '../../components/Create'
import PageLayout from '../../components/PageLayout'

const CreatePage = () => {
    return (
        <Create />
    )
}

export default CreatePage
